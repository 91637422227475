<template>
  <div class="mine-container">
    <Title name="我的合同"></Title>
    <!-- 查询和其他操作 -->
    <div class="filter-content">
      <el-form ref="listQuery" :model="filterForm" :inline="true" label-width="120px">
        <el-form-item label="">
          <el-input v-model="filterForm.orderNumber" clearable placeholder="请输入订单编号" style="width: 170px" size="mini" />
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="filterForm.orderType" clearable placeholder="请选择订单类型" style="width: 140px" size="mini">
            <el-option value="TS" label="普通订单" />
            <el-option value="RE" label="竞买订单" />
            <el-option value="AU" label="竞卖订单" />
            <el-option value="BI" label="竞标订单" />
          </el-select>
        </el-form-item>

        <el-form-item label="">
          <el-select v-model="filterForm.acontractStatus" clearable placeholder="请选择甲方签署状态" style="width: 170px" size="mini">
            <el-option value="0" label="未签署" />
            <el-option value="1" label="已签署" />
            <el-option value="2" label="已过期" />
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="filterForm.contractStatus" clearable placeholder="请选择乙方签署状态" style="width: 170px" size="mini">
            <el-option value="0" label="未签署" />
            <el-option value="1" label="已签署" />
            <el-option value="2" label="已过期" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" class="filter-item" type="primary" icon="el-icon-search" @click="this.getlist">查找</el-button>
        </el-form-item>
      </el-form>
      <!--      <el-input v-model="listQuery.goodsSn" clearable size="mini" class="filter-item" style="width: 200px;" placeholder="请输入商品编号"/>-->
      <!--      <el-input v-model="listQuery.name" clearable size="mini" class="filter-item" style="width: 200px;" placeholder="请输入商品名称"/>-->
      <!--      <el-button size="mini" class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">查找</el-button>-->
      <!--<el-button size="mini" class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">添加</el-button>-->
      <!-- <el-button :loading="downloadLoading" size="mini" class="filter-item" type="warning" icon="el-icon-download" @click="handleDownload">导出</el-button> -->
    </div>
    <div class="tabel-content">
      <el-table :header-cell-style="{background: '#f5f5f5', 'font-weight': 500, color: '#666', padding: '12 5px'}" :data="tableData" style="width: 100%">
        <el-table-column prop="orderNumber" label="订单编号" width="150"> </el-table-column>
        <el-table-column prop="contractNumber" label="合同编号" width="160">
          <template slot-scope="scope">
            <!--优先显示手动填写的合同编号，若没有手动填写的合同编号则显示系统自动生成的合同编号 -->
            {{ scope.row.contractNumber ? scope.row.contractNumber : scope.row.contractNo }}
          </template>
        </el-table-column>
        <el-table-column prop="nailClientinfoName" label="合同签署甲方" width="200"> </el-table-column>
        <el-table-column prop="clientinfoName" label="合同签署乙方" width="200"> </el-table-column>
        <el-table-column prop="acontractStatus" width="150" label="甲方签约状态">
          <template slot-scope="scope">
            {{ scope.row.acontractStatus | getContractStatus }}
          </template>
        </el-table-column>
        <el-table-column prop="contractStatus" width="150" label="乙方签约状态">
          <template slot-scope="scope">
            {{ scope.row.contractStatus | getContractStatus }}
          </template>
        </el-table-column>
        <el-table-column prop="contractUsername" width="100" label="签署人"> </el-table-column>
        <el-table-column prop="contractTime" width="150" label="签署时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="isShowBtn(scope.row)" size="small" round style="width: 80px" @click="toContractDetail(scope.row)">签署</el-button>
            <el-button v-if="scope.row.contractTime" size="small" round @click="openContract(scope.row.ascontractUrl)">查看合同</el-button>
            <el-button size="small" round @click="toOrderDetail(scope.row)">查看订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="pageManage.total">
        <el-pagination background layout="prev, pager, next" :total="pageManage.total" :page-size="pageManage.limit" :current-page="pageManage.page" @current-change="changeCurrentPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getMyContranct} from '../../api';
import Title from '../../component/Title';
import {permission} from '@/modules/mixin/permission-mixin.js';
export default {
  components: {
    Title
  },
  mixins: [permission],
  data() {
    return {
      statusType: [
        {value: '0', label: '未签署'},
        {value: '1', label: '已签署'},
        {value: '2', label: '已过期'}
      ],
      productPictureManage: {
        dialogImageUrl: '',
        dialogVisible: false
      },
      filterForm: {
        clientInfoName: '',
        orderNumber: '',
        orderType: '',
        contractStatus: '',
        acontractStatus: '',
        contractType: this.$route.query.customer === 'buyder' ? 'A' : 'B'
      },
      pageManage: {
        limit: 10,
        page: 1,
        total: 0
      },
      tableData: []
    };
  },
  created() {
    this.getMyContranct();
  },
  filters: {
    getContractStatus(val) {
      let statusText = '';

      if (!val) return statusText;

      switch (val) {
        case '0':
          statusText = '未签署';
          break;
        case '1':
          statusText = '已签署';
          break;
        case '2':
          statusText = '已过期';
          break;
      }
      return statusText;
    }
  },
  computed: {
    chehui: function() {
      this.tableData.forEach(item => {
        return new Date(item.startTime) < new Date() ? true : false;
      });
    }
  },
  methods: {
    isShowBtn(data) {
      let flag = false;
      if (this.$route.query.customer === 'buyder') {
        data.contractStatus === '1' ? (flag = true) : '';
      } else {
        data.contractStatus === '0' ? (flag = true) : '';
      }
      if (data.contractStatus === '1' && data.acontractStatus === '1') flag = false;
      // 判断是否包含合同签署权限 如不包含则不显示按钮
      if (this.isPermission('DQS', this.$route.query.customer)) flag = true;
      return flag;
    },
    getlist() {
      this.pageManage.page = 1;
      this.getMyContranct();
    },
    getMyContranct() {
      getMyContranct({
        ...this.filterForm,
        ...this.pageManage
      }).then(res => {
        if (res.errno === 0) {
          this.tableData = res.data.data;
          this.pageManage.total = res.data.total;
        }
      });
    },
    toOrderDetail(row) {
      if (row.orderType === 'TS') {
        this.$router.push({
          path: '/User/TransactionDetail',
          query: {
            orderid: row.orderId,
            customer: this.$route.query.customer,
            orderType: row.orderType
          }
        });
      } else if (row.orderType === 'BI') {
        this.$router.push({
          path: '/User/TransactionDetail',
          query: {
            orderid: row.orderId,
            customer: this.$route.query.customer,
            orderType: row.orderType
          }
        });
      } else {
        this.$router.push({
          path: '/User/TransactionDetail',
          query: {
            orderid: row.orderId,
            customer: this.$route.query.customer,
            orderType: row.orderType
          }
        });
      }
    },
    toContractDetail(data) {
      this.$router.push({
        path: '/User/TransactionDetail',
        query: {
          orderType: data.orderType,
          orderid: data.orderId,
          tciId: data.tciId,
          orderNum: data.orderNumber,
          customer: this.$route.query.customer,
          isHandleShow: true
        }
      });
    },
    openContract(url) {
      window.open(url);
    },
    changeCurrentPage(num) {
      this.pageManage.page = num;
      this.getMyContranct();
    }
  }
};
</script>

<style lang="less" scoped>
.mine-container {
  background: #fff;

  .pagination {
    padding: 20px;
    box-sizing: border-box;
    text-align: right;
  }
}

.filter-content {
  line-height: 40px;
  margin-top: 12px;
  padding: 10px;
}
.tabel-content {
  padding: 0 10px;
}

/deep/.el-form-item__label {
  font-size: 12px;
}

/deep/.el-table td,
.el-table th {
  padding: 6px 0;
}
</style>
